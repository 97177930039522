import React from 'react';
import StoreJson from './store.json';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';

export default function MgrConsole(props) {
  return (
    <>
      <AdminResource
        {...props}
        pageContext={{
          resource: {
            ...StoreJson,
            renderHeader: () => {
              return <h2>所有店家</h2>;
            },
          },
        }}
      />
    </>
  );
}
